import React, {memo} from "react";
import PropTypes from "prop-types";
import {PatternFormat} from "react-number-format";
import {Input} from "antd";
import "./style.less";

const PatternInput = memo(
  ({
    value,
    onChange,
    placeholder,
    format = "+7 (###) ###-##-##",
    mask = "_",
    ...props
  }) => {
    const handlePaste = (event) => {
      let pastedData = event.clipboardData.getData("Text");

      // Убираем лидирующие символы, если это код страны
      if (pastedData.startsWith("7") || pastedData.startsWith("8")) {
        pastedData = pastedData.slice(1);
      } else if (pastedData.startsWith("+")) {
        pastedData = pastedData.replace(/^\+7|^\+8/, "");
      }

      if (onChange) {
        onChange({target: {value: pastedData}});
      }
      event.preventDefault();
    };

    return (
      <PatternFormat
        {...props}
        value={value}
        onChange={onChange}
        format={format}
        mask={mask}
        customInput={Input}
        className="pattern-input__input"
        onPaste={handlePaste}
        placeholder={placeholder}
      />
    );
  }
);

PatternInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  mask: PropTypes.string,
};

export default PatternInput;
