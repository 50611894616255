/* eslint-disable no-console */
/* eslint-disable no-debugger */
import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Form, Input, Tabs} from "antd";
import * as AuthBackend from "../AuthBackend";
import * as Setting from "../../Setting";
import * as Util from "../Util";
import * as UserBackend from "../../backend/UserBackend";
import logoImage from "../../assets/logo_wt-min.png";
import "./style.less";
import CodeInput from "./components/CodeInput/CodeInput";
import PatternInput from "./components/PatternInput/PatternInput";
import {CaptchaModal} from "../../common/modal/CaptchaModal";

const CustomLoginPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState("phone");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [loginMethod, setLoginMethod] = useState("password");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [application, setApplication] = useState(props.application);
  const [oAuthParams, setOAuthParams] = useState(null);
  const [captchaVisible, setCaptchaVisible] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [availableLoginMethods, setAvailableLoginMethods] = useState([]);

  const validatePhone = useCallback((phone) => {
    const numericValue = phone.replace(/[\D\s]*/gi, "");
    return (
      (numericValue.length === 10 && !numericValue.startsWith("7")) ||
      (numericValue.startsWith("7") && numericValue.length === 11)
    );
  }, []);

  const validateEmail = useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  const validatePassword = useCallback((password) => {
    return password.length >= 6;
  }, []);

  const formatPhone = useCallback((phone) => {
    let numericValue = phone.replace(/[\D\s]*/gi, "");
    if (numericValue.startsWith("7") || numericValue.startsWith("8")) {
      numericValue = numericValue.slice(1);
    }
    return numericValue;
  }, []);

  const handleLoginRequest = useCallback(async(values) => {
    try {
      const res = await AuthBackend.login(values, oAuthParams);
      return res;
    } catch (err) {
      Setting.showMessage("error", `Ошибка: ${err.message}`);
      return null;
    }
  }, [oAuthParams]);

  const handleLoginResponse = useCallback((res, username = "") => {
    if (res?.status === "ok") {
      if (res.data === "NextMfa") {
        Setting.showMessage("error", "MFA не поддерживается в данный момент");
      } else if (res.data === "SelectPlan") {
        const pricing = res.data2;
        Setting.goToLink(`/select-plan/${pricing.owner}/${pricing.name}?user=${username}`);
      } else if (res.data === "BuyPlanResult") {
        const sub = res.data2;
        Setting.goToLink(`/buy-plan/${sub.owner}/${sub.pricing}/result?subscription=${sub.name}`);
      } else {
        const codeResponse = res.data;
        const concatChar = oAuthParams?.redirectUri?.includes("?") ? "&" : "?";
        const redirectUrl = `${oAuthParams.redirectUri}${concatChar}code=${codeResponse}&state=${oAuthParams.state}`;
        Setting.goToLink(redirectUrl);
      }
    } else if (res) {
      Setting.showMessage("error", `Ошибка входа: ${res.msg}`);
    }
  }, [oAuthParams]);

  const handleCaptchaOk = useCallback(async(type, token, secret) => {
    setCaptchaVisible(false);
    setLoading(true);
    const phone = formatPhone(phoneNumber);
    try {
      const res = await UserBackend.sendCode(
        type,
        token,
        secret,
        "register",
        "RU",
        phone,
        "phone",
        `${application?.owner}/${application?.name}`,
        ""
      );
      if (res) {
        setStage("code");
        setIsResendDisabled(true);
        setResendTimer(60);
      } else {
        Setting.showMessage("error", res.msg);
      }
    } catch (err) {
      Setting.showMessage("error", `Ошибка отправки кода: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [phoneNumber, application, formatPhone]);

  const handleCaptchaCancel = useCallback(() => {
    setCaptchaVisible(false);
  }, []);

  const handlePhoneSubmit = useCallback(async() => {
    if (!validatePhone(phoneNumber)) {
      Setting.showMessage("error", "Неверный формат номера телефона");
      return;
    }

    if (loading) {
      return;
    }

    setLoading(true);
    try {
      const captchaData = await UserBackend.getCaptcha(application?.owner, application?.name, false);
      if (captchaData.type === "none") {
        const phone = formatPhone(phoneNumber);
        const res = await UserBackend.sendCode(
          "none",
          "",
          "",
          "register",
          "RU",
          phone,
          "phone",
          `${application?.owner}/${application?.name}`,
          ""
        );
        if (res) {
          setStage("code");
          setIsResendDisabled(true);
          setResendTimer(60);
        } else {
          Setting.showMessage("error", res.msg);
        }
      } else {
        setCaptchaVisible(true);
      }
    } catch (err) {
      Setting.showMessage("error", `Ошибка отправки кода: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [phoneNumber, application, formatPhone, validatePhone, loading]);

  const handleCodeSubmit = useCallback(async() => {
    if (!code) {
      Setting.showMessage("error", "Введите код");
      return;
    }

    setLoading(true);
    const phone = formatPhone(phoneNumber);
    const values = {
      application: application?.name,
      organization: application?.organization,
      phone: phone,
      phonePrefix: "+7",
      countryCode: "RU",
      phoneCode: code,
      type: "code",
    };

    try {
      const res = await AuthBackend.loginAutoRegister(values, oAuthParams);
      handleLoginResponse(res);
    } catch (err) {
      Setting.showMessage("error", `Ошибка входа: ${err.message}`);
    }
    setLoading(false);
  }, [code, phoneNumber, application, oAuthParams, handleLoginResponse, formatPhone]);

  const handlePasswordSubmit = useCallback(async() => {
    if (!validateEmail(email)) {
      Setting.showMessage("error", "Неверный формат email");
      return;
    }

    if (!validatePassword(password)) {
      Setting.showMessage("error", "Пароль должен быть не менее 6 символов");
      return;
    }

    setLoading(true);
    const values = {
      application: application?.name,
      organization: application?.organization,
      username: email,
      password: password,
      autoSignin: false,
      signinMethod: "Password",
      type: oAuthParams?.responseType || "code",
    };

    const res = await handleLoginRequest(values);
    handleLoginResponse(res, email);
    setLoading(false);
  }, [email, password, application, oAuthParams, handleLoginRequest, handleLoginResponse, validateEmail, validatePassword]);

  const handlePhoneChange = useCallback((e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    const numericValue = value.replace(/[\D\s]*/gi, "");
    setIsSubmitButtonDisabled(
      !((numericValue.length === 10 && !numericValue.startsWith("7")) ||
      (numericValue.startsWith("7") && numericValue.length === 11))
    );
  }, []);

  useEffect(() => {
    let timer;
    if (isResendDisabled && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    }
    if (resendTimer === 0) {
      setIsResendDisabled(false);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isResendDisabled, resendTimer]);

  const handleResendCode = async() => {
    setIsResendDisabled(true);
    setResendTimer(60);
    try {
      const captchaData = await UserBackend.getCaptcha(application?.owner, application?.name, false);
      if (captchaData.type === "none") {
        const res = await UserBackend.sendCode(
          "none",
          "",
          "",
          "register",
          "RU",
          formatPhone(phoneNumber),
          "phone",
          `${application?.owner}/${application?.name}`,
          ""
        );
        if (res.status === "ok") {
          Setting.showMessage("success", "Код отправлен повторно");
        } else {
          Setting.showMessage("error", res.msg);
        }
      } else {
        setCaptchaVisible(true);
      }
    } catch (err) {
      Setting.showMessage("error", `Ошибка отправки кода: ${err.message}`);
      setIsResendDisabled(false);
      setResendTimer(0);
    }
  };

  const determineAvailableLoginMethods = useCallback(() => {
    if (!application?.signinMethods?.length) {
      return ["password", "phone"]; // По умолчанию разрешаем оба метода
    }

    const methods = [];
    application.signinMethods.forEach(method => {
      if (method.name === "Password") {
        methods.push("password");
      } else if (method.name === "Verification code") {
        if (method.rule === "Phone only") {
          methods.push("phone");
        } else if (method.rule === "Email only") {
          methods.push("password");
        } else {
          methods.push("password", "phone");
        }
      }
    });

    return methods;
  }, [application]);

  useEffect(() => {
    const methods = determineAvailableLoginMethods();
    setAvailableLoginMethods(methods);
    // Устанавливаем начальный метод входа
    if (methods.length === 1) {
      setLoginMethod(methods[0]);
    }
  }, [application, determineAvailableLoginMethods]);

  useEffect(() => {
    document.body.setAttribute("data-page", "custom-login");
    document.documentElement.style.setProperty("--logo-image", `url(${logoImage})`);

    const params = new URLSearchParams(window.location.search);
    const type = params.get("type") || "login";

    const oauthParams = Util.getOAuthGetParameters();
    if (oauthParams?.clientId) {
      setOAuthParams({
        clientId: oauthParams.clientId,
        responseType: oauthParams.responseType,
        redirectUri: oauthParams.redirectUri,
        scope: oauthParams.scope,
        state: oauthParams.state,
        nonce: oauthParams.nonce,
        challengeMethod: oauthParams.challengeMethod,
        codeChallenge: oauthParams.codeChallenge,
        type: type,
      });
    } else if (type === "code" && window.location.pathname.includes("/login")) {
      Setting.showMessage("error", "Ошибка: отсутствуют необходимые параметры OAuth");
    }

    return () => {
      document.body.removeAttribute("data-page");
      document.documentElement.style.removeProperty("--logo-image");
    };
  }, []);

  useEffect(() => {
    setApplication(props.application);
  }, [props.application]);

  const renderPhoneForm = () => (
    <Form className="login-phone-form">
      <div className="login-phone-form__mobile-spacer" />
      <div className="login-phone-form__logo" />
      <div>
        <h3 className="login-phone-form__header">Войти в личный кабинет</h3>
      </div>
      <div>
        <Form.Item>
          <PatternInput
            value={phoneNumber}
            onChange={handlePhoneChange}
            placeholder="+7 (___) ___-__-__"
          />
        </Form.Item>

        <Button
          type="primary"
          size="large"
          block
          disabled={isSubmitButtonDisabled}
          loading={loading}
          onClick={handlePhoneSubmit}
          className="login-phone-form__submit-button"
        >
          Получить код
        </Button>
      </div>
    </Form>
  );

  const renderCodeForm = () => (
    <Form className="login-sms-code-form">
      <div className="login-sms-code-form__logo" />
      <div>
        <p className="login-sms-code-form__description">
          Мы отправили код на номер {phoneNumber}
        </p>
        <div className="login-sms-code-form__actions">
          <Button onClick={() => setStage("phone")}>
            Изменить номер телефона
          </Button>
          <Button
            disabled={isResendDisabled}
            onClick={handleResendCode}
          >
            {isResendDisabled
              ? `Отправить код повторно (${resendTimer}с)`
              : "Отправить код повторно"}
          </Button>
        </div>

        <Form.Item>
          <CodeInput value={code} onChange={setCode} />
        </Form.Item>

        <Button
          type="primary"
          size="large"
          block
          loading={loading}
          onClick={handleCodeSubmit}
          className="login-sms-code-form__submit-button"
        >
          Войти
        </Button>
      </div>
    </Form>
  );

  const renderPasswordForm = () => (
    <Form className="login-phone-form">
      <div className="login-phone-form__mobile-spacer" />
      <div className="login-phone-form__logo" />
      <div>
        <h3 className="login-phone-form__header">Войти в личный кабинет</h3>
      </div>
      <div>
        <Form.Item>
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Button
          type="primary"
          size="large"
          block
          loading={loading}
          onClick={handlePasswordSubmit}
          className="login-sms-code-form__submit-button"
        >
          Войти
        </Button>
      </div>
    </Form>
  );

  const renderLoginForm = () => {
    if (loginMethod === "phone") {
      if (stage === "phone") {
        return renderPhoneForm();
      } else {
        return renderCodeForm();
      }
    } else {
      return renderPasswordForm();
    }
  };

  const items = [
    {
      label: "По почте и паролю",
      key: "password",
    },
    {
      label: "По номеру телефона",
      key: "phone",
    },
  ];

  if (!application.name || !application.organization) {
    Setting.showMessage("error", "Ошибка: не удалось загрузить данные приложения");
    return null;
  }

  return (
    <div className="custom-login-page">
      <div className="custom-login-container">
        <div>
          {availableLoginMethods.length > 1 ? (
            <>
              <Tabs
                className="signin-methods"
                items={items}
                size="small"
                activeKey={loginMethod}
                onChange={(key) => {
                  setLoginMethod(key);
                  setStage("phone");
                }}
                centered
              />
              {renderLoginForm()}
            </>
          ) : (
            renderLoginForm()
          )}
        </div>
        <CaptchaModal
          owner={application?.owner}
          name={application?.name}
          visible={captchaVisible}
          onOk={handleCaptchaOk}
          onCancel={handleCaptchaCancel}
          isCurrentProvider={false}
        />
      </div>
    </div>
  );
};

CustomLoginPage.propTypes = {
  application: PropTypes.shape({
    name: PropTypes.string,
    organization: PropTypes.string,
    owner: PropTypes.string,
  }).isRequired,
};

export default CustomLoginPage;
